/*****    jQuery extensions *****/
//replace_select_options
//options ::= [{text: "one", value: 1},{text: "two", value: 2}]
//allow_blank ::== true || false

import jstz from 'jstimezonedetect';
import jQuery from './jquery_global';

(function($, window) {
    $.fn.replace_options = function(options,allow_blank) {
    var self, $option;

    this.empty();
    self = this;
    if (allow_blank) { self.append($("<option></option>")); }
    $.each(options, function(index, option) {
      $option = $("<option></option>")
        .attr("value", option.value)
        .text(option.text);
      self.append($option);
    });
  };
})(jQuery, window);

export function setCookie(cookName, cookValue, expiry) {
    var dt = new Date();
    dt.setTime(dt.getTime() + (expiry*24*60*60*1000));
    var expires = "expires="+ dt.toUTCString();
    document.cookie = cookName + "=" + cookValue + ";" + expiry + ";path=/";
}

export function getCookie(cookName) {
    var cname = cookName + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var cookArray = decodedCookie.split(';');
    for(var i = 0; i < cookArray.length; i++) {
        var c = cookArray[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(cname) == 0) {
            return c.substring(cname.length, c.length);
        }
    }
    return '';
}

export function findTimeZone() {
  const oldIntl = window.Intl
  try {
    window.Intl = undefined
    const tz = jstz.determine().name()
    window.Intl = oldIntl
    return tz
  } catch (e) {
    // sometimes (on android) you can't override intl
    return jstz.determine().name()
  }
}

export function jsonHeader(){
    return {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
    };
}

/*
 * http://unixpapa.com/js/querystring.html
*/

export function QueryString(qs)
{
    this.dict= {};

    // If no query string  was passed in use the one from the current page
    if (!qs) qs= location.search;

    // Delete leading question mark, if there is one
    if (qs.charAt(0) == '?') qs= qs.substring(1);

    // Parse it
    var re= /([^=&]+)(=([^&]*))?/g;
    var match;
    while (match= re.exec(qs))
    {
        var key= decodeURIComponent(match[1].replace(/\+/g,' '));
        var value= match[3] ? QueryString.decode(match[3]) : '';
        if (this.dict[key])
            this.dict[key].push(value);
        else
            this.dict[key]= [value];
    }
}

QueryString.decode= function(s)
{
    s= s.replace(/\+/g,' ');
    s= s.replace(/%([EF][0-9A-F])%([89AB][0-9A-F])%([89AB][0-9A-F])/gi,
        function(code,hex1,hex2,hex3)
        {
            var n1= parseInt(hex1,16)-0xE0;
            var n2= parseInt(hex2,16)-0x80;
            if (n1 == 0 && n2 < 32) return code;
            var n3= parseInt(hex3,16)-0x80;
            var n= (n1<<12) + (n2<<6) + n3;
            if (n > 0xFFFF) return code;
            return String.fromCharCode(n);
        });
    s= s.replace(/%([CD][0-9A-F])%([89AB][0-9A-F])/gi,
        function(code,hex1,hex2)
        {
            var n1= parseInt(hex1,16)-0xC0;
            if (n1 < 2) return code;
            var n2= parseInt(hex2,16)-0x80;
            return String.fromCharCode((n1<<6)+n2);
        });
    s= s.replace(/%([0-7][0-9A-F])/gi,
        function(code,hex)
        {
            return String.fromCharCode(parseInt(hex,16));
        });
    return s;
};

QueryString.prototype.value= function (key)
{
    var a= this.dict[key];
    return a ? a[a.length-1] : undefined;
};

QueryString.prototype.values= function (key)
{
    var a= this.dict[key];
    return a ? a : [];
};

QueryString.prototype.keys= function ()
{
    var a= [];
    for (var key in this.dict)
        a.push(key);
    return a;
};


// var TokenTemplate = {
//     parseToken: function(template_node) {
// 	return document.body.querySelector(template_node).innerHTML.split(/\$\{(.+?)\}/g);
//     },
//     render: function(props) {
// 	return function(tok, i) { return (i % 2) ? props[tok] : tok; };
//     },
//     compose: function(items, template_node) {
// 	return items.map(function(item) {
// 	    return TokenTemplate.parseToken(template_node).map(TokenTemplate.render(item)).join('');
// 	}).join('');
//     }
// };
