import { getCookie, setCookie, findTimeZone } from './base_ext.js';

/* Change slide in Slider */
function change_slide(in_id, out_id){
    var slide_in = jQuery("#home-slider #slides > li:nth-child(" + in_id + ")");
    var slide_out = jQuery("#home-slider #slides > li:nth-child(" + out_id + ")");
    if (in_id>out_id) {
	slide_in.slideDown(600, function(){
	    slide_out.hide();
	});
    } else {
	slide_in.show(0, function(){
	    slide_out.slideUp(600);
	});
    }
}

function slide(btnclicked){
    if (!btnclicked.hasClass('active-slide') &&
	!jQuery("#home-slider #slides > li").is(':animated')) {
	var slide_id_in = btnclicked.attr('data-id');
	var slide_id_out = jQuery(".btn-slider a.active-slide").attr('data-id');
	change_slide(slide_id_in, slide_id_out);
	jQuery(".btn-slider a").removeClass();
	btnclicked.addClass('active-slide');
	return false;
    }
}

function autoRotate() {
    var rotate = setInterval(function(){
	var id_active = jQuery("#home-slider .btn-slider a.active-slide").attr('data-id');
	var id_in = ++id_active;
	var id_last = jQuery("#home-slider .btn-slider li:last a").attr('data-id');
	if (id_in > id_last) id_in = 1;
	slide(jQuery("#home-slider .btn-slider li a[data-id='" + id_in + "']"));
    }, 8000); /* <<< Auto rotate slides on every 8 secs */
}


/* Modal Lightbox */
jQuery(".trigger-modal").hover(
	function() {
	    jQuery(this).children("a").animate({
		opacity: 1,
		top: "+=25%"
	    }, 300);
	},
	function() {
	    jQuery(this).children("a").stop().css({
		'opacity': 0,
		'top': '25%'});
	});

function alter_logo_img(){
    $('body.home-index.lang-zh-CN #main-wrapper .navbar-brand img').prop('src', '/images/logo/talentlines_white.png');
    $('body.home-index.lang-en #main-wrapper .navbar-brand img').prop('src', '/images/logo/talentlists_white.png');
}

function init_home_slider(){
    var btn_slider = jQuery("#home-slider .btn-slider");
    btn_slider.css('margin-left', -(btn_slider.width()/2)+3);

    // pause auto rotation on btn-slider hover
    jQuery("#home-slider .btn-slider li a").hover(
	function() { clearInterval(rotate); },
	function() { autoRotate(); })

    // change slide on btn-slider button click
    jQuery("#home-slider .btn-slider li a").click(function() {
	slide(jQuery(this));
	jQuery("html, body").stop().animate({scrollTop: 0}, 'slow');
	return false;
    });
    if ($("#home-slider").length){
	autoRotate();
    }
}

function init_ui_shot_modal(){
    jQuery(".trigger-modal a.pop-full").click(function(e){
	e.preventDefault();

	$('#ui-shot-modal img').prop('src', $(this).prev().prop('src'));
	$('#ui-shot-modal').modal();
    });
}

function equal_column_height(){
    var left =  $('.nav-left')
    var right = $('.task-body')
    if (left.height() < right.height()) left.height(right.height());
}

function set_app_time_zone(){
    let browser_time_zone = getCookie('browser_time_zone');
    if (!browser_time_zone) {
	var timezone = findTimeZone();
	setCookie('browser_time_zone', timezone, 1);
    }
}

function set_cookie_consent_form(){
	const cookieContainer = document.getElementById("cookie-consent-popup");
    if (!cookieContainer) return;

	const acceptButton = document.querySelector('#cookie-consent-popup button.accept');
	if (!acceptButton) return;

	acceptButton.addEventListener('click', () => {
		cookieContainer.classList.add('hide');
		localStorage.setItem("cookieConsent", "true");
	});
	setTimeout(()=> {
		if(!localStorage.getItem('cookieConsent')){
			cookieContainer.classList.remove('hide');
		}
	}, 2000);
}

$(document).ready(function(){
    alter_logo_img();      //home page
    init_home_slider();    //home page
    init_ui_shot_modal();  //home page
    equal_column_height(); //activities.html.erb  users
    set_app_time_zone();
	set_cookie_consent_form();

    $('#session_email').focus();
});
